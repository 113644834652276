import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb capell de 2 a 7 cm de diàmetre, primer acampanat per passar a pla conservant un petit mamelló central. És de color gris fosc a quasi negre, més fosc cap al centre. Les làmines són fràgils, sinuades, amb moltes lamel·les i de color blanquinós grisenc. El peu, de 4-8 x 1-2 cm, és blanc, cilíndric i llis. Les espores blanques en massa, són el·líptiques, de 5-7 x 4-5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      